import React, { useEffect, useState } from 'react';
import ArrowRightIcon from "./ArrowRightIcon";

const BetaWrapper = () => {
    const [isBetaTester, setIsBetaTester] = useState(false);
    const [textInput, setTextInput] = useState("");
    const [error, setError] = useState(false);
    const [showPassword, setShowPassword] = useState("");

    const checkIfBetaAuthenticated = async () => {
        setError(true);
    };

    const handleSubmit = () => {
        checkIfBetaAuthenticated();
    };

    return (
        <div className="w-screen h-[100dvh] bg-primary flex flex-col items-center">
            <div className="md:max-w-[800px] grow flex flex-col items-center text-center md:p-16 max-md:mt-16 p-2">
                <img src="/logo.png" className="lg:w-64 w-56" alt="Logo" />
                <div className="lg:text-4xl text-2xl font-semibold lg:mt-16 mt-4">
                    Welcome to the{" "}
                    <span className="lg:text-4xl text-2xl font-semibold px-4 bg-primary3 text-white rounded-full inline">
                        closed-beta
                    </span>{" "}
                    of find-your-CRM.de
                </div>
                <p className="lg:text-md lg:mt-16 mt-4 md:mx-12 max-md:mt-16">
                    We are pleased that you are interested in testing our product. Please enter the invite code you received below. We are looking forward to your feedback.
                </p>

                <div className="relative h-20 mt-16 mt-4 w-full border rounded-full flex items-center justify-between bg-white border-secondary6">
                    <input
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                setError(false);
                                handleSubmit();
                            }
                        }}
                        onChange={(event) => {
                            setError(false);
                            setTextInput(event.target.value);
                        }}
                        className="pl-4 pr-[30%] pr-32 h-full w-full bg-transparent rounded-full placeholder:text-secondary4 placeholder:text-sm placeholder:font-normal font-semibold text-lg"
                        type={showPassword ? "text" : "password"}
                        name="search"
                        id="search"
                        placeholder="Insert your invite code"
                    />
                    <div className='max-md:w-[30%] absolute right-2 flex items-center gap-2'>
                        <button
                            type="button"
                            className="transform text-secondary4"
                            onClick={() => setShowPassword(prev => !prev)}
                        >
                            {showPassword ? (
                                <div className='w-[30px] mx-auto'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke={"currentColor"} className="w-full h-full">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                </div>
                                ) : (
                                <div className='w-[30px] mx-auto'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke={"currentColor"} className="w-full h-full">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                                    </svg>
                                </div>
                                
                            )}
                        </button>

                        <button
                            className="h-16 sm:w-36 grow max-sm:px-2 text-white hover:text-primary3 border border-transparent hover:border-primary3 bg-primary3 hover:bg-white flex items-center justify-center rounded-full gap-4 font-semibold transition-all"
                            onClick={handleSubmit}
                        >
                            Enter
                            <div className="h-6 w-6 max-md:hidden">
                                <ArrowRightIcon />
                            </div>
                        </button>
                    </div>
                </div>

                <div className="w-full">
                    {error && (
                        <p className="text-xs italic text-rose-500 text-center">
                            Unfortunately, the invite code you entered does not work. Please try again or send us an e-mail to receive a new invite code.
                        </p>
                    )}
                </div>

                <p className="text-xs font-light mt-4 lg:mx-24">
                    You don't have an invite code yet, but would like to test find-your-CRM.de? Then please send us an{" "}
                    <a className="cursor-pointer underline hover:underline-offset-2 transition-all" href="mailto:hi@sectorlens.de">
                        e-mail
                    </a>.
                </p>

                <div className='flex flex-col items-center justfiy-center gap-4 opacity-20 hover:opacity-100 transition-all max-md:mt-16'>
                    <p className="lg:text-md lg:mt-16 mt-4 md:mx-12 text-center px-4">Looking for other types of software? You'll find it here:</p>
                    <div className='flex flex-wrap items-center justify-center gap-2'>
                        {
                            ["software", "bi", "crm", "erp", "esg", "hr", "mes", "wms"].map(item => (
                                <a href={`https://find-your-${item}.de`} target='_blank' ><img src={`/find-your/${item}.png`} className='h-8' /></a>
                            ))
                        }
                    </div>
                </div>
            </div>

            <div className="text-center flex max-sm:flex-col sm:gap-4 items-center text-xs font-light max-md:mt-16">
                <p>
                    © 2024{" "}
                    <a target="_blank" href="https://www.sectorlens.de" className="cursor-pointer underline hover:underline-offset-2 transition-all">
                        Sectorlens GmbH
                    </a>
                    . All Rights Reserved.
                </p>
                <div className='flex gap-4'>
                    <a
                        className="cursor-pointer underline hover:underline-offset-2 transition-all"
                        target="_blank"
                        href="https://www.sectorlens.de/imprint"
                    >
                        Imprint
                    </a>
                    <a
                        className="cursor-pointer underline hover:underline-offset-2 transition-all"
                        target="_blank"
                        href="https://www.sectorlens.de/privacy-policy"
                    >Privacy policy</a>
                </div>
            </div>
        </div>
    );
};

export default BetaWrapper;
